import * as React from "react"
import Img from "gatsby-image"
import { useStyletron } from "baseui"
import { Cell } from "baseui/layout-grid"
import { Block } from "baseui/block"
import { Link } from "gatsby"
import Fade from "react-reveal"
import { ItemTitle } from "../typography"

const CaseStudyCard = ({ content }) => {
  const [css] = useStyletron()

  return content.map((item, index) => (
    <Cell
      span={4}
      // eslint-disable-next-line react/no-array-index-key
      key={`card-item-${index}`}
    >
      <Link to={`/${item.slug}`}>
        <div
          className={css({
            outline: `none`,
            backgroundColor: `white`,
            boxShadow: `1px 1px 15px 0.1px lightgrey;`,
            height: `350px`,
            width: `330px`,
          })}
        >
          <Img
            fixed={item.imgixImage.fixed}
            alt={item.frontmatter.title}
            style={{ height: "250px", width:"330px"}}
          />
          <ItemTitle marginTop="14px" marginLeft="14px" marginRight="14px">
            {item.frontmatter.title}
          </ItemTitle>
        </div>
      </Link>
    </Cell>
  ))
}

export default CaseStudyCard
