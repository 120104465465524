import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Button } from "baseui/button"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import Fade from "react-reveal/Fade"
import Img from "gatsby-image"
import Image from "../../components/Image"
import {
  Title,
  Description,
  ItemTitle,
  ItemDescription,
} from "../../components/typography"
import FlushedGrid from "../../components/FlushedGrid"
import Container from "../../components/UI/Container"

const BlockchainForensics = () => {
  const data = useStaticQuery(graphql`
    query {
      cryptoJson {
        BLOCKCHAIN_FORENSICS {
          title
          description
          icon {
            publicURL
          }
        }
      }
      imgixImage(url: "/uploads/investigate-resize.png") {
        fluid(maxWidth: 800) {
          ...GatsbyImgixFluid
        }
      }
    }
  `)

  return (
    <Block paddingTop="60px" paddingBottom="60px" id="kyt-feature">
      <Container>
        <FlushedGrid gridGutters={[0, 56]} gridGaps={48}>
          <Cell span={[12, 6]}>
            <Block
              display="flex"
              alignItems="center"
              height="100%"
              maxWidth="600px"
              marginLeft={["18px", "10%", "20%", 0]}
            >
              <Img
                fluid={{ ...data.imgixImage.fluid }}
                style={{ width: "100%" }}
                alt="Blocklynx Image"
              />
            </Block>
          </Cell>
          <Cell span={[12, 6]}>
            <Block
              display="flex"
              flexDirection="column"
              marginLeft={["18px", "10%", "20%", 0]}
            >
              <Title>Understand On-chain and Cross-chain Behavior</Title>
              <Description paddingBottom="30px">
                Get unparalleled clarity on activities within the cryptocurrency
                and virtual asset space
              </Description>
              <FlushedGrid gridGaps={[28, 36]}>
                {data.cryptoJson.BLOCKCHAIN_FORENSICS.map(item => (
                  <Cell key={`feature-${item.title}`} span={12}>
                    <Fade up>
                      <Block display="flex">
                        <Image
                          src={item.icon.publicURL}
                          alt={item.title}
                          style={{
                            width: "50px",
                            height: "50px",
                            marginBottom: "20px",
                            marginRight: "10px",
                            borderWidth: "2px",
                            borderColor: "rgb(233, 227, 254)",
                            borderRadius: "10px",
                            backgroundColor: "rgb(243, 242, 250)",
                            padding: "8px 10px",
                          }}
                        />
                        <Block marginLeft="15px">
                          <ItemTitle>{item.title}</ItemTitle>
                          <ItemDescription>{item.description}</ItemDescription>
                        </Block>
                      </Block>
                    </Fade>
                  </Cell>
                ))}
              </FlushedGrid>
              <Link to="/contact-us">
                <Button>Request Custom Investigation</Button>
              </Link>
            </Block>
          </Cell>
        </FlushedGrid>
      </Container>
    </Block>
  )
}

export default BlockchainForensics
