import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
// import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"
// import Slide from "react-reveal/Slide"
// import { Breadcrumbs } from "baseui/breadcrumbs"
// import { StyledLink } from "baseui/link"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import {
  Title,
  SubTitle,
  ItemTitle,
  // ItemDescription,
  Description,
} from "../../components/typography"
import Text from "../../components/Text"
import FlushedGrid from "../../components/FlushedGrid"
import Container from "../../components/UI/Container"
import { boxShadow } from "styled-system"

const BlocklynxUseCase = () => {
  const Data = useStaticQuery(graphql`
    query {
      importoptions: imgixImage(url: "/uploads/activity-profile.png") {
        fluid(maxWidth: 800) {
          ...GatsbyImgixFluid
        }
      }
      layout: imgixImage(url: "/uploads/source-of-funds.png") {
        fluid(maxWidth: 800) {
          ...GatsbyImgixFluid
        }
      }
      styling: imgixImage(url: "/uploads/on-chain-analysis.png") {
        fluid(maxWidth: 800) {
          ...GatsbyImgixFluid
        }
      }
    }
  `)

  return (
    <Block paddingTop={["50px", "80px"]} paddingBottom={["50px", "80px"]}>
      <Container>
        <Block marginBottom={["40px", "40px", "40px", "60px"]}>
          <SubTitle textAlign="center">Case Study</SubTitle>
          <Title textAlign="center">
            Transaction Monitoring for Cryptocurrency Exchanges
          </Title>
        </Block>
        <Block marginTop="24px">
          <FlushedGrid gridGutters={[0, 24]} gridGaps={24}>
            <Cell span={[12, 6]} align="center" order={[2, 1]}>
              <Fade up>
                <Block
                  display="flex"
                  flexDirection="column"
                  marginLeft={["18px", "10%", "20%", 0]}
                >
                  <ItemTitle>Activity Profile Overview</ItemTitle>

                  <Block marginLeft="14px">
                    <Description paddingBottom="8px">
                      Get a bird's eye view of user activity across multiple
                      entities. Complete with detailed
                      information on transactional counterparties and volumes.
                    </Description>
                  </Block>
                </Block>
              </Fade>
            </Cell>
            <Cell span={[12, 6]} align="center" order={[1, 2]}>
              <Fade up>
                <Block
                  display="flex"
                  alignItems="center"
                  height="100%"
                  maxWidth="800px"
                  marginLeft={["18px", "10%", "20%", 0]}
                >
                  <Img
                    fluid={{ ...Data.importoptions.fluid }}
                    style={{ width: "100%" }}
                    alt="Connections Image"
                  />
                </Block>
              </Fade>
            </Cell>
          </FlushedGrid>
        </Block>

        <Block marginTop="24px">
          <FlushedGrid gridGutters={[0, 24]} gridGaps={24}>
            <Cell span={[12, 6]} align="center" order={[2, 1]}>
              <Fade up>
                <Block
                  display="flex"
                  flexDirection="column"
                  marginLeft={["18px", "10%", "20%", 0]}
                >
                  <ItemTitle>Break Down Sources of Funds</ItemTitle>

                  <Block marginLeft="14px">
                    <Description paddingBottom="8px">
                      Filter and rapidly identify transactions from sources of
                      concern. Transactions are marked with date and time
                      information to facilitate further investigations.
                    </Description>
                  </Block>
                </Block>
              </Fade>
            </Cell>
            <Cell span={[12, 6]} align="center" order={[1, 2]}>
              <Fade up>
                <Block
                  display="flex"
                  alignItems="center"
                  height="100%"
                  maxWidth="600px"
                  marginLeft={["18px", "10%", "20%", 0]}
                >
                  <Img
                    fluid={{ ...Data.layout.fluid }}
                    style={{ width: "100%" }}
                    alt="Connections Image"
                  />
                </Block>
              </Fade>
            </Cell>
          </FlushedGrid>
        </Block>

        <Block marginTop="24px">
          <FlushedGrid gridGutters={[0, 24]} gridGaps={24}>
            <Cell span={[12, 6]} align="center" order={[2, 1]}>
              <Fade up>
                <Block
                  display="flex"
                  flexDirection="column"
                  marginLeft={["18px", "10%", "20%", 0]}
                >
                  <ItemTitle>On-Chain Red Flags</ItemTitle>
                  <Block marginLeft="14px">
                    <Description paddingBottom="8px">
                      With the on-chain analytics tool, common red flags
                      associated with suspicious transactions detected are
                      highlighted.
                    </Description>
                  </Block>
                </Block>
              </Fade>
            </Cell>
            <Cell span={[12, 6]} align="center" order={[1, 2]}>
              <Fade up>
                <Block
                  display="flex"
                  alignItems="center"
                  height="100%"
                  maxWidth="600px"
                  marginLeft={["18px", "10%", "20%", 0]}
                >
                  <Img
                    fluid={{ ...Data.styling.fluid }}
                    style={{ width: "100%" }}
                    alt="Connections Image"
                  />
                </Block>
              </Fade>
            </Cell>
          </FlushedGrid>
        </Block>
      </Container>
    </Block>
  )
}

export default BlocklynxUseCase
