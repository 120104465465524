import React from "react"

import { Block } from "baseui/block"
import { SEOPage } from "../../components/SEO"
import Layout from "../../components/layout"
import BannerPlain from "../../containers/BannerPlainSection"
import BlocklynxUseCase from "../../containers/BlockchainUseCase/BlocklynxUseCase"
import BlockchainForensics from "../../containers/BlockchainUseCase/BlockchainForensics"
import DiscoverTransactionMonitoring from "../../containers/MainLanding/DiscoverTransactionMonitoring"
import ForensicsCaseStudy from "../../containers/BlockchainUseCase/ForensicsCaseStudy"
import { Newsletter } from "../../containers/Newsletter"
import BannerBG from "../../images/banner-blocklynx.png"

export default () => {
  return (
    <Layout>
      <SEOPage
        title="Blockchain Forensics Solutions"
        description="Network analytics for blockchain forensics. Connect the dots between entities and wallets and trace the trail of suspicious funds."
      />
      <BannerPlain
        title="Monitor Transactions on the Blockchain"
        subTitle="Connect the dots between chains and trace the source of funds"
        background={BannerBG}
        backgroundSize="cover"
        backgroundColor="#0C6B58"
      />
      <BlockchainForensics />
      <BlocklynxUseCase />
      <ForensicsCaseStudy />
      <DiscoverTransactionMonitoring />
      <Block marginBottom="150px" />
      <Newsletter ButtonText="Download the Infographic Report" />
    </Layout>
  )
}
