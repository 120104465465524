import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import { Button } from "baseui/button"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
// import Img from "gatsby-image"
import { Title, SubTitle } from "../../components/typography"
import FlushedGrid from "../../components/FlushedGrid"
import Container from "../../components/UI/Container"
import CaseStudyCard from "../../components/CaseStudyCard"

const ForensicsCaseStudy = () => {
  const Data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {
          slug: {
            in: [
              "blog/efficient-large-graph-label-propagation-algorithm"
              "blog/understanding-the-structure-of-exchanges-on-the-ethereum-chain"
              "blog/tracing-the-trail-of-the-upbit-hack"
            ]
          }
        }
      ) {
        nodes {
          slug
          frontmatter {
            title
            hero_image
          }
          imgixImage {
            fixed(height: 250, width: 330) {
              ...GatsbyImgixFixed
            }
          }
        }
      }
    }
  `)
  return (
    <Block paddingTop="100px" paddingBottom="100px" id="discover-blocklynx">
      <Container>
        <Block
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginBottom={["18px", "42px"]}
        >
          <SubTitle>More Case Studies from Our Blog</SubTitle>
          <Title>Visualizing Anomalies on the Blockchain</Title>
        </Block>
        <Block alignContent="center">
          <FlushedGrid gridGutters={[0, 56]} gridGaps={48}>
            <CaseStudyCard content={Data.allMdx.nodes} />
          </FlushedGrid>
        </Block>
      </Container>
    </Block>
  )
}

export default ForensicsCaseStudy
