import React from "react"
import { Block } from "baseui/block"
import { Display, DisplayDescription } from "../../components/typography"
import Container from "../../components/UI/Container"

const BannerPlainSection = ({
  title,
  subTitle,
  background,
  backgroundSize,
  backgroundColor,
}) => {
  return (
    <Block
      id="banner_section"
      paddingTop={["120px", "120px", "180px"]}
      paddingBottom={["50px","50px","120px","160px"]}
      backgroundImage={`url(${background})`}
      backgroundSize={backgroundSize}
      backgroundColor={backgroundColor}
    >
      <Container>
        <Block
          display="flex"
          flexDirection="column"
          marginRight="auto"
          maxWidth={["100%", "100%", "900px", "560px"]}>
          <Display color="white">{title}</Display>
          <DisplayDescription color="white">{subTitle}</DisplayDescription>
        </Block>
      </Container>
    </Block>
  )
}

export default BannerPlainSection
